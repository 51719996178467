import {
   Box,
   Chip,
   Dialog,
   DialogContent,
   DialogTitle,
   FormControl,
   InputLabel,
   MenuItem,
   Paper,
   Select,
   SelectChangeEvent,
   Slider,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   TextField,
   Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import lookup from 'country-code-lookup';
import { omit } from 'lodash';
import { FC, ReactElement, useState } from 'react';
import {
   AegisObservationModel,
   AegisReportModel,
   useAegisReportsListQuery,
} from '../../../../shared/utilities/__generated__/graphql';
import WhitelistPhoneNumberButton from '../../components/WhitelistPhoneNumberButton';

const AegisOverviewScreen: FC<void> = (): ReactElement => {
   const [page, setPage] = useState(1);
   const [pageSize, setPageSize] = useState(100);
   const [ipAddress, setIpAddress] = useState('');
   const [phoneNumber, setPhoneNumber] = useState('');

   const [report, viewReport] = useState<AegisReportModel | null>(null);

   const [blockedQuery, setIsBlockedQuery] = useState<string>('all');

   const [usedQuery, setUsedQuery] = useState<string>('all');

   const [uiScore, setUIScore] = useState<number[]>([0, 5000]);
   const [score, setScore] = useState<number[]>([0, 5000]);

   const handleScoreUpdate = (_: Event, newValue: number | number[]) => {
      setUIScore(newValue as number[]);
   };

   const handleScoreChange = (_: Event, newValue: number | number[]) => {
      setScore(newValue as number[]);
   };

   const handleBlockedChange = (event: SelectChangeEvent) => {
      setIsBlockedQuery(event.target.value);
   };

   const handleUsedChange = (event: SelectChangeEvent) => {
      setUsedQuery(event.target.value);
   };

   const { loading, data } = useAegisReportsListQuery({
      variables: {
         page: page || 1,
         take: pageSize,
         blocked: blockedQuery === 'all' ? null : blockedQuery === 'blocked',
         minSocre: score[0],
         maxScore: score[1],
         used: usedQuery === 'all' ? null : usedQuery === 'used',
         ip: ipAddress.length ? ipAddress : null,
         phoneNumber: phoneNumber.length ? phoneNumber : null,
      },
   });

   const columns: GridColDef[] = [
      { field: 'phoneNumber', headerName: 'Phone number', width: 150 },
      {
         field: 'score',
         headerName: 'Score',
         width: 80, //
         renderCell: ({ value: score }) => {
            let color: any = 'info';

            if (score > 200) {
               color = 'warning';
            }

            if (score > 350) {
               color = 'error';
            }

            return <Chip label={score} color={color} />;
         },
      },
      {
         field: 'blocked',
         headerName: 'Status',
         width: 110, //
         type: 'boolean',
         renderCell: ({ value: blocked }) => {
            return (
               <Chip label={blocked ? 'Blocked' : 'OK'} color={blocked ? 'error' : 'success'} />
            );
         },
      },
      {
         field: 'ipCountry',
         headerName: 'IP Country',
         valueGetter: ({ value: ipCountry }) => {
            return ipCountry ? lookup.byIso(ipCountry)?.country : null;
         },
         width: 120,
      },
      {
         field: 'requestedAt',
         headerName: 'Created at',
         type: 'dateTime',
         width: 180,
         valueGetter: ({ value }) => value && new Date(value),
      },
      {
         field: 'usedAt',
         headerName: 'Used at',
         type: 'dateTime',
         width: 180,
         valueGetter: ({ value }) => value && new Date(value),
      },
      {
         field: 'context',
         headerName: 'Context',
      },
      {
         field: 'userId',
         headerName: 'Has user',
         type: 'boolean',
         valueGetter: ({ value }) => {
            return !!value;
         },
      },
      {
         field: 'observations',
         headerName: 'Observations',
         flex: 1,
         renderCell: ({ value: observations, row }) => {
            return (
               <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                  {observations.map((e: any) => {
                     const obs = row.observations as AegisObservationModel[];

                     const points = +(
                        obs
                           .map((o) => o.observations)
                           .flat()
                           .filter((o) => o[0] === e)?.[0]?.[1] ?? 0
                     );

                     return (
                        <Chip
                           sx={{ mr: 0.5, mb: 0.5, mt: 0.5 }}
                           key={e}
                           label={e}
                           size='small'
                           color={
                              points === 0
                                 ? 'info'
                                 : points === 100
                                 ? 'error'
                                 : points > 0
                                 ? 'warning'
                                 : 'success'
                           }
                        />
                     );
                  })}
               </Box>
            );
         },
         valueFormatter: ({ value: observations }) => {
            return observations.join(', ');
         },

         valueGetter: ({ value: observations, row }) => {
            const score = row.score;
            return Object.entries(
               observations
                  .map((o: { observations: any }) => o.observations)
                  .filter((o: string | any[]) => o.length)
                  .map((a: Iterable<readonly [PropertyKey, any]>) => Object.fromEntries(a))
                  .reduce((result: any, item: any) => ({ ...result, ...item }), {}),
            )
               .sort((a, b) => Math.abs(b[1] as number) - Math.abs(a[1] as number))
               .map(([observation, points]) => {
                  // if (
                  //    (score >= 20 && (points as number) < 0) ||
                  //    (score < 20 && (points as number) > 0)
                  // ) {
                  //    return null;
                  // }

                  return observation;
               })
               .filter(Boolean)
               .filter(
                  (o) =>
                     ![
                        'APP_CHECK_TOKEN_VALID',
                        'APP_CHECK_TOKEN_PROVIDED',
                        'IS_LOGIN',
                        'IS_REGISTRATION',
                        'CALLING_CODE_MATCHES_IP_COUNTRY',
                        'REQUEST_KEY_PROVIDED',
                        'REQUEST_KEY_VALID',
                        'USER_EXISTS',
                        'USER_OLDER_THAN_30_DAYS',
                        'VALID_PHONE_NUMBER',
                        'LAUNCHED_IP_COUNTRY',
                        'LEGIT_USER_AGENT',
                        'LEGIT_DEVICE_NAME',
                        'LAUNCHED_PHONE_COUNTRY_CALLING_CODE',
                     ].includes(o!),
               );
         },
      },
      // TODO: Add suspension
   ];

   const scoreMarks = [
      {
         value: 0,
         label: '0',
      },

      {
         value: 350,
         label: 'BLOCK',
      },
      {
         value: 5000,
         label: '5000',
      },
   ];

   return (
      <div style={{ height: '90%', width: '100%' }}>
         {/* filters */}
         <Box sx={{ mb: 2, display: 'flex', alignContent: 'center', alignItems: 'center' }}>
            {/* status */}
            <FormControl sx={{ minWidth: 120, mr: 1 }} size='small'>
               <InputLabel id='demo-select-small-label'>Status</InputLabel>
               <Select value={blockedQuery} label='Status' onChange={handleBlockedChange}>
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'blocked'}>Blocked</MenuItem>
                  <MenuItem value={'non-blocked'}>Non blocked</MenuItem>
               </Select>
            </FormControl>

            {/* used */}
            <FormControl sx={{ minWidth: 120, mr: 1 }} size='small'>
               <InputLabel id='demo-select-small-label'>Used</InputLabel>
               <Select value={blockedQuery} label='Status' onChange={handleUsedChange}>
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'used'}>Used</MenuItem>
                  <MenuItem value={'not-used'}>Not used</MenuItem>
               </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120, mr: 1 }} size='small'>
               <TextField
                  label='IP Address'
                  value={ipAddress}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                     setIpAddress(event.target.value);
                  }}
                  size='small'
               />
            </FormControl>

            <FormControl sx={{ minWidth: 120, mr: 1 }} size='small'>
               <TextField
                  label='Phone number'
                  value={phoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                     setPhoneNumber(event.target.value);
                  }}
                  size='small'
               />
            </FormControl>

            {/* score */}
            <Box sx={{ width: 300, ml: 2 }}>
               <Slider
                  value={uiScore}
                  onChange={handleScoreUpdate}
                  onChangeCommitted={handleScoreChange as any}
                  valueLabelDisplay='auto'
                  marks={scoreMarks}
                  min={0}
                  max={5000}
                  defaultValue={5000}
               />
            </Box>
         </Box>

         <DataGrid
            rows={data?.aegisReports ?? []}
            loading={loading}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5]}
            pagination
            page={page}
            pageSize={pageSize}
            checkboxSelection
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            sx={{
               height: '85vh',
               '.MuiTablePagination-displayedRows': {
                  display: 'none', // 👈 to hide huge pagination number
               },
            }}
            paginationMode='server'
            rowCount={Number.MAX_VALUE} // 👈 to maximize pages
            onRowClick={(params, event) => {
               viewReport(data?.aegisReports.find((r) => r.id === params.id) ?? null);
            }}
            getRowHeight={({ model }) => {
               // Get the observations for this row
               const observationsCount = model.observations?.length ?? 0;
               // Calculate how many rows of chips we might need (assuming ~5 chips per row)
               const estimatedRows = Math.ceil(observationsCount / 4);
               // Each row of chips needs about 32px, plus some padding
               return estimatedRows * 22 + 20;
            }}
         />
         {/* <Box sx={{ m: 2 }}>
            {selectedRows.length} suggestion(s) selected
            <Button
               sx={{ ml: 2 }}
               variant='contained'
               disabled={!selectedRows.length || deleting}
               onClick={deleteSelectedSuggestions}
            >
               Delete selected
            </Button>
         </Box> */}
         <Dialog
            open={report !== null}
            onClose={() => {
               viewReport(null);
            }}
            scroll={'paper'}
         >
            <DialogTitle>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {report?.phoneNumber}
                  <WhitelistPhoneNumberButton phoneNumber={report?.phoneNumber ?? ''} />
               </Box>
            </DialogTitle>
            <DialogContent dividers>
               <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                     <TableBody>
                        {[
                           ...Object.entries(
                              omit(report as any, [
                                 'headers',
                                 'observations',
                                 'stats',
                                 'executionTime',
                              ]) ?? {},
                           ).map(([key, value]) => ({
                              key: key,
                              value: value ? (value as any).toString() : 'N/A',
                           })),
                           {
                              key: 'executionTime',
                              value: `${Math.round(report?.executionTime ?? 0)} ms`,
                           },
                        ].map(({ key, value }) => (
                           <TableRow
                              key={key}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell component='th' scope='row' sx={{ width: 200 }}>
                                 {convertCamelCaseToTitleCase(key)}
                              </TableCell>
                              <TableCell align='left'>
                                 {key === 'ipAddress' ? (
                                    <a
                                       href={`https://www.ip-tracker.org/locator/ip-lookup.php?ip=${value}`}
                                       target='_blank'
                                       rel='noreferrer'
                                       onClick={(e) => {
                                          e.preventDefault();

                                          viewReport(null);
                                          setIpAddress(value);
                                       }}
                                    >
                                       {value}
                                    </a>
                                 ) : key === 'phoneNumber' ? (
                                    <a
                                       target='_blank'
                                       href='#'
                                       onClick={(e) => {
                                          e.preventDefault();

                                          viewReport(null);
                                          setPhoneNumber(value);
                                       }}
                                    >
                                       {value}
                                    </a>
                                 ) : key.toLocaleLowerCase().includes('country') &&
                                   value != 'N/A' ? (
                                    lookup.byIso(value)?.country
                                 ) : (
                                    value
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
               <Typography id='modal-modal-title' variant='h5' sx={{ mb: 2 }}>
                  Observations
               </Typography>
               {report?.observations
                  .filter((o) => o.observations.length)
                  .map((observation) => (
                     <>
                        <Typography id='modal-modal-title' variant='subtitle1' sx={{ mb: 2 }}>
                           {observation.category}
                        </Typography>
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                           <Table size='small'>
                              <TableBody>
                                 {observation.observations
                                    .map(([observation, points]) => ({
                                       observation,
                                       points: points ?? 0,
                                    }))
                                    .map(({ observation, points }) => (
                                       <TableRow
                                          key={observation}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                       >
                                          <TableCell
                                             component='th'
                                             scope='row'
                                             sx={{ width: 1000 }}
                                          >
                                             {observation}
                                          </TableCell>
                                          <TableCell align='right'>
                                             <Chip
                                                label={points}
                                                color={
                                                   +points === 0
                                                      ? 'info'
                                                      : +points > 0
                                                      ? 'warning'
                                                      : 'success'
                                                }
                                             />
                                          </TableCell>
                                       </TableRow>
                                    ))}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </>
                  ))}
               <Typography id='modal-modal-title' variant='h5' sx={{ mb: 2 }}>
                  Stats
               </Typography>
               {report && (
                  <TableContainer component={Paper} sx={{ mb: 2 }}>
                     <Table size='small'>
                        <TableBody>
                           {report.stats
                              .map(([key, value]) => ({
                                 key: key,
                                 value: value ? (value as any).toString() : 'N/A',
                              }))
                              .map(({ key, value }) => (
                                 <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                 >
                                    <TableCell component='th' scope='row' sx={{ width: 1000 }}>
                                       {convertCamelCaseToTitleCase(key)}
                                    </TableCell>
                                    <TableCell align='left'>{value}</TableCell>
                                 </TableRow>
                              ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               )}
               <Typography id='modal-modal-title' variant='h5' sx={{ mb: 2 }}>
                  Headers
               </Typography>
               {report && (
                  <TableContainer component={Paper} sx={{ mb: 2 }}>
                     <Table size='small'>
                        <TableBody>
                           {Object.entries(JSON.parse(report?.headers ?? ''))
                              .map(([key, value]) => ({
                                 key: key,
                                 value: value ? (value as any).toString() : 'N/A',
                              }))
                              .map(({ key, value }) => (
                                 <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                 >
                                    <TableCell component='th' scope='row' sx={{ width: 1000 }}>
                                       {key}
                                    </TableCell>
                                    <TableCell align='left'>{decodeURIComponent(value)}</TableCell>
                                 </TableRow>
                              ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               )}
            </DialogContent>
         </Dialog>
      </div>
   );
};

export default AegisOverviewScreen;

const convertCamelCaseToTitleCase = (inputString: string): string => {
   // Replace capital letters with a space followed by the lowercase version of the letter
   const titleCaseString = inputString.replace(/([A-Z])/g, ' $1').toLowerCase();

   // Capitalize the first letter of the entire string
   return (titleCaseString.charAt(0).toUpperCase() + titleCaseString.slice(1))
      .replaceAll('Ip', 'IP')
      .replaceAll('Id', 'ID')
      .replaceAll('id', 'ID');
};
